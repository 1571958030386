import React from 'react'
import Layout from "../components/Layout"
import StyledHero from "../components/StyledHero"
import { graphql } from 'gatsby'
import Places from '../components/Places/Places'
import SEO from "../components/SEO"
import ZipCodeSearch from "../components/Search/ZipCodeSearch"
import Banner from "../components/Banner";

export const query = graphql`
query {
    defaultBcg: file(relativePath: {eq: "replace-house-sunlight.jpg"}) {
        childImageSharp {
            fluid(quality: 90, maxWidth: 1160) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`;

export default function places({ data }) {
    return (
        <Layout>
            <SEO title="Places" description="Search Rehabs." />
            <StyledHero img={data.defaultBcg.childImageSharp.fluid}>
                <Banner
                    title="Rehabs"
                    info="Find a rehab closer to home or with more options."
                >
                <ZipCodeSearch />
                </Banner>
            </StyledHero>
            <Places />
        </Layout>
    )
}
